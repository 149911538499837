

  @keyframes fadeInImg
  {
    from {opacity: 0}
    to {opacity: 1}
  }

.img-loading
{
   opacity: 0;
   width: 100%;
   height: auto;
}
.wrapper{
  text-align: center;
  margin-left:2em;
  margin-right:2em;
}

.img-loaded
{
   animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   animation-fill-mode: forwards;
   animation-duration: 0.7s;
   animation-delay: 0.1s;
}

.photoContainer{
    overflow: hidden;
    width:70vw;
    height: 50vw;
    margin:0 auto;
    display: grid;
    grid-template-rows: 9fr 1fr;
    grid-gap: 10px;
    margin-left:15vw;
}

.videoContainer{

  overflow: hidden;
  display:block;
  padding-top: 5vh;
  display: grid;
  grid-gap: 10px;
  justify-content: center;

}

.hide{
  display:none;
}
.gallery {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    display: flex;
    border: solid 1px;
    filter: drop-shadow(0 0 1rem black);
  }
  
  .gallery__img {
    scroll-snap-align: start;
    margin-bottom: 10px;
    object-fit: cover;

  }
  
  .lil-nav {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    grid-row-start: 2;
  }

  .thumbnail{
    width:auto;
    height:10vh;
    margin-left:2px;
    margin-right:2px;
 }
  
  .lil-nav__img {
    object-fit: cover;
    width:auto;
    height:100%;
    filter: saturate(0);
    transition: 0.3s ease all;  
  }

  .lil-nav__img:hover {
    transform: scale(1.05);
    filter: saturate(1);
  }

  .link {
    display: flex;
    font-size:0.7rem;
	}
	.icon {
    margin-right: 0.5vw;
	  width: 20;
	  height: 20;
	}


  .breadcrumb {
    margin-left:2vh;
  }

.downloader{
  margin-top:2vh;
  margin-bottom:2vh;
}

.listingDetails{
  text-align:left;
  margin-left:15vw;
  margin-top:2vh;
  margin-bottom:2vh;
	font-weight: 600; 
	font-size: medium; 
	background-color: #f44336; 
  color: beige; 
  width:70vw;
  display:grid;
}

.MuiTypography-colorTextSecondary {
	color: rgb(255 0 0 / 54%) !important;
	padding: 10px !important;
	background-color: #efefef !important;
	margin-top: 2em !important;
	margin-left: 1em !important;
	margin-right: 1em !important;
}

.attributes{
  margin-top:5vh;
  margin-left:3vw;
  margin-bottom:5vh;
  line-height:1.5em;
}

.visibilityHidden{
  visibility: hidden;
}