
.container {
	position:absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	min-height:100%;
	display: grid;
	grid-template-rows: 5fr 90fr 5fr;
}

.header {
	background-color: #fff;
	height: 10vh;
	margin-left: 3em;
	margin-right: 3em;
	grid-template-columns: 15fr 70fr 15fr;
	padding-bottom: 0.7em;
	padding-top: 1em;
	border-bottom: 0.01em solid #f44336;
	display:grid;
	align-items: flex-start;
}

.footer {
	display: grid;
	color: beige;
	background-color: darkgreen;
	padding: 10px;
	font-weight: 900;
	font-size: 12px;
	margin-top: 60px;
	height:2em;
	width:100%;
	bottom: 0;
    left: 0;
    right: 0;

}

p {
	margin: 0;
	padding: 0;
}

.listingTable {
	padding-left: 2em;
	padding-right: 2em;
}

.hlvLogo {
	height: 100%;
	width: auto;
}

MuiTableCell-root .MuiTableCell-head .jss13 .MuiTableCell-alignLeft {
	background-color: #f44336;
	color: Beige !important;
}

MuiFormControl-root MuiTextField-root jss10 {
	min-width: 150px;
	padding-left: 16px;
	border: solid !important;
}

Jss12 {
	overflow-x: auto;
	position: relative;
	font-size: 14px;
}

.skeleton{
	margin-top:2%;
	margin-left:5%;
}

.filterCities{
	width:40vw;
	font-size:small;
	margin-left:1vw;
	margin-top:1vw;
	padding:1vw;
}