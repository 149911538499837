@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
	position:absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	min-height:100%;
	display: grid;
	grid-template-rows: 5fr 90fr 5fr;
}

.header {
	background-color: #fff;
	height: 10vh;
	margin-left: 3em;
	margin-right: 3em;
	grid-template-columns: 15fr 70fr 15fr;
	padding-bottom: 0.7em;
	padding-top: 1em;
	border-bottom: 0.01em solid #f44336;
	display:grid;
	align-items: flex-start;
}

.footer {
	display: grid;
	color: beige;
	background-color: darkgreen;
	padding: 10px;
	font-weight: 900;
	font-size: 12px;
	margin-top: 60px;
	height:2em;
	width:100%;
	bottom: 0;
    left: 0;
    right: 0;

}

p {
	margin: 0;
	padding: 0;
}

.listingTable {
	padding-left: 2em;
	padding-right: 2em;
}

.hlvLogo {
	height: 100%;
	width: auto;
}

MuiTableCell-root .MuiTableCell-head .jss13 .MuiTableCell-alignLeft {
	background-color: #f44336;
	color: Beige !important;
}

MuiFormControl-root MuiTextField-root jss10 {
	min-width: 150px;
	padding-left: 16px;
	border: solid !important;
}

Jss12 {
	overflow-x: auto;
	position: relative;
	font-size: 14px;
}

.skeleton{
	margin-top:2%;
	margin-left:5%;
}

.filterCities{
	width:40vw;
	font-size:small;
	margin-left:1vw;
	margin-top:1vw;
	padding:1vw;
}


  @-webkit-keyframes fadeInImg
  {
    from {opacity: 0}
    to {opacity: 1}
  }

@keyframes fadeInImg
  {
    from {opacity: 0}
    to {opacity: 1}
  }

.img-loading
{
   opacity: 0;
   width: 100%;
   height: auto;
}
.wrapper{
  text-align: center;
  margin-left:2em;
  margin-right:2em;
}

.img-loaded
{
   -webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
           animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   -webkit-animation-fill-mode: forwards;
           animation-fill-mode: forwards;
   -webkit-animation-duration: 0.7s;
           animation-duration: 0.7s;
   -webkit-animation-delay: 0.1s;
           animation-delay: 0.1s;
}

.photoContainer{
    overflow: hidden;
    width:70vw;
    height: 50vw;
    margin:0 auto;
    display: grid;
    grid-template-rows: 9fr 1fr;
    grid-gap: 10px;
    margin-left:15vw;
}

.videoContainer{

  overflow: hidden;
  display:block;
  padding-top: 5vh;
  display: grid;
  grid-gap: 10px;
  justify-content: center;

}

.hide{
  display:none;
}
.gallery {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    display: flex;
    border: solid 1px;
    -webkit-filter: drop-shadow(0 0 1rem black);
            filter: drop-shadow(0 0 1rem black);
  }
  
  .gallery__img {
    scroll-snap-align: start;
    margin-bottom: 10px;
    object-fit: cover;

  }
  
  .lil-nav {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    grid-row-start: 2;
  }

  .thumbnail{
    width:auto;
    height:10vh;
    margin-left:2px;
    margin-right:2px;
 }
  
  .lil-nav__img {
    object-fit: cover;
    width:auto;
    height:100%;
    -webkit-filter: saturate(0);
            filter: saturate(0);
    transition: 0.3s ease all;  
  }

  .lil-nav__img:hover {
    transform: scale(1.05);
    -webkit-filter: saturate(1);
            filter: saturate(1);
  }

  .link {
    display: flex;
    font-size:0.7rem;
	}
	.icon {
    margin-right: 0.5vw;
	  width: 20;
	  height: 20;
	}


  .breadcrumb {
    margin-left:2vh;
  }

.downloader{
  margin-top:2vh;
  margin-bottom:2vh;
}

.listingDetails{
  text-align:left;
  margin-left:15vw;
  margin-top:2vh;
  margin-bottom:2vh;
	font-weight: 600; 
	font-size: medium; 
	background-color: #f44336; 
  color: beige; 
  width:70vw;
  display:grid;
}

.MuiTypography-colorTextSecondary {
	color: rgb(255 0 0 / 54%) !important;
	padding: 10px !important;
	background-color: #efefef !important;
	margin-top: 2em !important;
	margin-left: 1em !important;
	margin-right: 1em !important;
}

.attributes{
  margin-top:5vh;
  margin-left:3vw;
  margin-bottom:5vh;
  line-height:1.5em;
}

.visibilityHidden{
  visibility: hidden;
}

.container {
	position:absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	min-height:100%;
	display: grid;
	grid-template-rows: 5fr 90fr 5fr;
}

.header {
	background-color: #fff;
	height: 10vh;
	margin-left: 3em;
	margin-right: 3em;
	grid-template-columns: 15fr 70fr 15fr;
	padding-bottom: 0.7em;
	padding-top: 1em;
	border-bottom: 0.01em solid #f44336;
	display:grid;
	align-items: flex-start;
}

.footer {
	display: grid;
	color: beige;
	background-color: darkgreen;
	padding: 10px;
	font-weight: 900;
	font-size: 12px;
	margin-top: 60px;
	height:2em;
	width:100%;
	bottom: 0;
    left: 0;
    right: 0;

}

p {
	margin: 0;
	padding: 0;
}


.hlvLogo {
	height: 100%;
	width: auto;
}

.loginContainer{
    width:30%;
    height:50%;
    align-self: center;
    justify-self: center;
}

.submitButton{
    width:50% !important;
    margin-top:5% !important;
}

@media (orientation: portrait) and (max-width: 600px) {

	.loginContainer{
		width:60%;
		height:50%;
		align-self: center;
		justify-self: center;
	}

}

